import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'norteservicios';
  defaultImage = '../assets/images/placeholder.png';
  // image = 'https://images.unsplash.com/photo-1443890923422-7819ed4101c0?fm=jpg';
  image = '../assets/images/norte_remis_1.svg';
}
