<div class="row " style="margin-bottom: auto; height: 100%; background-image: url(../assets/images/placeholder.png);    background-position: center;
        background-repeat: no-repeat;
        background-size: cover;" lazyLoad="../assets/images/gruas_norte_san_luis_1.jpeg">

  <!--// SMARTPHONE-->
  <div class=" hide-on-med-and-up">
    <div class="col s12 right-align hide-on-med-and-up" style=" margin-top: 80px">
      <div class="row"></div>
      <div class="row"></div>
      <div class="row"></div>
      <div class=" col s12 center-align">
        <img class="responsive-img left-align" style="width: 90%;height: 90%"
          src="../assets/images/gruas_norte_sanluis_white.png">
      </div>
      <div class="col s12 center-align">
        <h1 class="grey-text white-text"
          style=" font-family: 'Lato', sans-serif; font-size: 40px; text-shadow: 10px 5px 5px black; margin-bottom: 0%;">
          266-5006666</h1>
        <h1 class="grey-text white-text"
          style=" font-family: 'Lato', sans-serif; font-size: 30px; text-shadow: 8px 4px 4px black; margin-top: 0%;">
          266-5007594</h1>
        <h1 class="grey-text text-darken-4 "
          style="display: none ;font-family: 'Lato', sans-serif; font-size: 40px; text-shadow: 15px 15px 15px #aaa">taxi
          en San Luis
        </h1>
        <div id="taxilinks" class="row center-align scrollspy hide-on-med-and-up">
          <div>
            <p class="white-text"
              style="font-weight: bold; font-size: 25px; -webkit-text-stroke: 1px rgb(0, 0, 0); -webkit-text-fill-color: rgb(255, 255, 255);">
              ¡Llamá a una grúa ahora!</p>
          </div>
          <a href="tel:+542665006666"
            class="btn-floating waves-effect waves-light btn-large hoverable z-depth-2 deep-orange accent-3 pulse"><i
              class="material-icons right white-text">phone_in_talk</i></a>
        </div>
      </div>
    </div>
  </div>


  <!--// LAPTOP-->
  <div class=" hide-on-small-and-down">
    <!--// LAPTOP-->
    <div class="row">
      <div class="col l6" style="margin-top: 50px">
        <div id="slider1" class="slider " style=" height: 200px;">
          <ul class="slides transparent ">
            <li>
              <img class="hide responsive-img" src="images/Whatsapp.png">
              <div class="caption left-align">
                <h3 class="white-text text-darken-4">GRUAS</h3>
                <h5 class="light white-text">Cobertura en todo San Luis </h5>
              </div>
            </li>
            <li>
              <div class="caption left-align ">
                <h3 class="white-text text-darken-4">Siempre Disponible</h3>
                <h5 class="light white-text">Las 24hs, los 356 días al año</h5>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="col l5  right-align hide-on-small" style="margin-top: 100px">

        <img class="responsive-img left-align" style="width: 90%;height: 90%"
          src="../assets/images/gruas_norte_sanluis_white.png">
      </div>
    </div>

    <div class="col l12 center-align" style="margin: 0px; padding: 0px">
      <h1 class="white-text text-darken-4"
        style=" font-family: 'Lato', sans-serif;   font-size: 85px; text-shadow: 10px 5px 5px black; margin: 0px">
        266-5006666</h1>
    </div>
    <div class="col l12 center-align" style="margin: 0px; padding: 0px">
      <h1 class="white-text text-darken-4 "
        style=" font-family: 'Lato', sans-serif;   font-size: 85px; text-shadow: 10px 5px 5px black; margin: 0px">
        266-5007594</h1>
    </div>
  </div>
</div>







<div class="carousel carousel-slider center">
  <div class="carousel-item  red white-text">
    <h2>First Panel</h2>
    <p class="white-text">This is your first panel</p>
    <div class="slider fullscreen">
      <ul class="slides">
        <li>
          <img src="../assets/images/gruas_norte_san_luis_2.jpeg">
        </li>
        <li>
          <img src="../assets/images/gruas1.jpeg">
        </li>
        <li>
          <img src="../assets/images/gruas3.jpeg">
          <div class="caption left-align">
            <h3>FLOTA</h3>
            <h5 class="light grey-text text-lighten-3">Disponemos de una extensa flota de Grúas</h5>
          </div>
        </li>
        <li>
          <img src="../assets/images/gruas10.jpeg">
        </li>
        <li>
          <img src="../assets/images/gruas4.jpeg">
          <div class="caption right-align">
            <h3>Siempre Disponibles</h3>
            <h5 class="light grey-text text-lighten-3">Cobertura las 24 hs.</h5>
          </div>
        </li>
        <li>
          <img src="../assets/images/gruas7.jpeg">
          <div class="caption center-align">
            <h3>Diversas Líneas de llamadas</h3>
            <h5 class="light grey-text text-lighten-3">Llámenos a cualquiera de nuestras líneas
              telefónicas</h5>
          </div>
        </li>
        <li>
          <img src="../assets/images/gruas11.jpeg">
        </li>
      </ul>
    </div>
  </div>
</div>












<!--  ..........................................................................................
                                       FOOTER
  ............................................................................................  -->


<!--        LAPTOP-->
<div class="row contenedor-div hide-on-small-and-down " style=" margin-bottom: auto; height: 100%; background-image: url(../assets/images/gruas4.jpeg);    background-position: center;
        background-repeat: no-repeat;
        background-size: cover;" lazyLoad="../assets/images/gruas7.jpeg">
  <div class="mi-imagen-abajo-derecha">
    <footer class="page-footer" style=" background-color: #ff3d00">
      <div class="container">
        <div class="row">
          <div class="col l7 s12">
            <h5 class="white-text">Gruas Norte San Luis</h5>
            <p class="grey-text text-lighten-4">Gruas Norte San Luis es una empresa de San Luis especializada en el servicio de gruas y remolques de urgencia o cualquier tipo de auxilio mecánico.</p>
            <p>
              <span style="font-weight: bold;">Palabras clave:</span>
              <span style="border-radius: 2%; border-radius: 5%;" class="badge light-blue darken-4 white-text">Gruas en San Luis</span>
              <span style="border-radius: 2%; border-radius: 5%;" class="badge light-blue darken-4 white-text">Remolque en San Luis</span>
              <span style="border-radius: 2%; border-radius: 5%;" class="badge light-blue darken-4 white-text">Auxilio Mecánico en San Luis</span>
              <span style="border-radius: 2%; border-radius: 5%; margin-top: 4px;" class="badge light-blue darken-4 white-text">Rescate en San Luis</span>
              <span style="border-radius: 2%; border-radius: 5%; margin-top: 4px;" class="badge light-blue darken-4 white-text">Auxilio Automotor en San Luis</span>
            </p>
          </div>
          <div class="col l4 offset-l1 s12">
            <div class="row">
              <div class="col s12 left-align">
                <div class="col s12 left-align">
                  <h5 class="white-text">Contacto</h5>
                </div>
              </div>
              <div class="col s12 left-align">
                <div class="col s1 left-align">
                  <i class="material-icons white-text left-align">email</i>
                </div>
                <div class="col s11 left-align">
                  <a class="grey-text text-lighten-3" href="mailto:r-norte-sl@hotmail.com">r-norte-sl@hotmail.com</a>
                </div>
              </div>
              <div class="col s12 left-align">
                <div class="col s1 left-align">
                  <i class="material-icons white-text left-align">phone</i>
                </div>
                <div class="col s11 left-align">
                  <a class="grey-text text-lighten-3">266-5006666 - 266-5007594</a>
                </div>
              </div>
              <div class="col s12 left-align">
                <div class="col s1 left-align">
                  <i class="material-icons white-text left-align">desktop_windows</i>
                </div>
                <div class="col s11 left-align">
                  <a class="grey-text text-lighten-3"><span> </span>www.gruasnortesanluis.com</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="footer-copyright black">
        <div class="container">
          <div class="center-align">Todos los derechos reservados © 2023</div>
        </div>
      </div>
    </footer>
  </div>
</div>



<!--        SMARPHONE-->
<footer class="page-footer hide-on-med-and-up" style=" background-color: #ff3d00">
  <div class="container">
    <div class="row">
      <div class="col l6 s12">
        <div class="row">
          <div class="col s12 left-align">
            <div class="col s12 left-align">
              <h5 class="white-text">Gruas Norte San Luis</h5>
              <p class="grey-text text-lighten-4">Gruas Norte San Luis es una empresa de San Luis especializada en el servicio de gruas y remolques de urgencia o cualquier tipo de auxilio mecánico.</p>
            </div>
            <div class="col s12 left-align">
              <p style="font-weight: bold">Palabras clave:</p>
              <div class="col s12">
                <div class="wrapperClass">
                  <span class="badgeClass" style="margin: 5px;">Rescate en San Luis</span>
                </div>
                <div class="wrapperClass">
                  <span class="badgeClass" style="margin: 5px;">Auxilio Automotor en San Luis</span>
                </div>
                <div class="wrapperClass">
                  <span class="badgeClass" style="margin: 5px;">Auxilio Mecánico San Luis</span>
                </div>
                <div class="wrapperClass">
                  <span class="badgeClass" style="margin: 5px;">Remolque en San Luis</span>
                </div>
                <div class="wrapperClass">
                  <span class="badgeClass" style="margin: 5px;">Gruas en San Luis</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col l4 offset-l2 s12">
        <div class="row">
          <div class="col s12 left-align">
            <div class="col s12 left-align">
              <h5 class="white-text">Contacto</h5>
            </div>
          </div>
          <div class="col s12 left-align">
            <div class="col s1 left-align">
              <i class="material-icons white-text left-align">email</i>
            </div>
            <div class="col s11 left-align">
              <a class="grey-text text-lighten-3" href="mailto:r-norte-sl@hotmail.com">r-norte-sl@hotmail.com</a>
            </div>
          </div>
          <div class="col s12 left-align">
            <div class="col s1 left-align">
              <i class="material-icons white-text left-align">phone</i>
            </div>
            <div class="col s11 left-align">
              <a class="grey-text text-lighten-3">266-5006666 - 266-5007594</a>
            </div>
          </div>
          <div class="col s12 left-align">
            <div class="col s1 left-align">
              <i class="material-icons white-text left-align">desktop_windows</i>
            </div>
            <div class="col s11 left-align">
              <a class="grey-text text-lighten-3"><span> </span>www.gruasnortesanluis.com</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="footer-copyright black">
    <div class="container">
      <div class="center-align">Todos los derechos reservados © 2023</div>
    </div>
  </div>
</footer>